import React, { Component } from "react"
import Layout from "../components/layout"
import moment from "moment"
import SEO from "../components/seo"

//NPM
import styled from "styled-components"
import { InlineShareButtons } from "sharethis-reactjs"
import { graphql } from "gatsby"
import MainButton from "../components/main-button"
import { AppointmentButton } from "../components/appointment/appointment-button"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { Link } from "gatsby"
import RedirectLoader from "../components/redirect-loader"

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 150px 20px 20px;

  @media (max-width: 750px) {
    padding: 50px 20px 20px;
  }
`
const ImageContainer = styled.div`
  width: 100%;
  max-height: 55vh;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
`
const PostImage = styled.img`
  max-height: 55vh;
  height: 100%;
  object-fit: cover;
  width: 100%;
  margin: 0;
`
const Title = styled.h1`
  margin-bottom: 10px;
  font-size: 2.2rem;
  font-weight: 600;
`

const Meta = styled.div`
  margin-bottom: 2rem;
`
const PostDate = styled.p`
  color: #898989;
  font-size: 0.9rem;
  margin: 0 5px 8px 0;
`
const Content = styled.div`
  line-height: 1.7;
  font-size: 1rem;
  margin-top: 1.5rem;

  p {
    font-family: "Articulat";
    font-size: 1rem;
  }

  img {
    object-fit: cover;
    max-height: 60vh;
    border-radius: 12px;
  }

  h4 {
    font-size: 1.1rem;
  }

  h5 {
    font-size: 1rem;
  }

  figure {
    font-family: "Articulat";
    font-size: 0.9rem;
    text-align: center;
  }

  li {
    font-size: 0.95rem;
    font-family: "Articulat";
  }
`
const ShareTitle = styled.h1`
  margin-top: 40px;
  text-align: center;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: bold;
`
const Author = styled.p`
  margin-bottom: 8px;
  font-family: "Articulat";
  font-weight: 500;
  font-style: italic;
  font-size: 14px;
`
const BottomNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px 0;
`
const Previous = styled.div`
  display: flex;
  align-items: center;
`
const Next = styled.div``
class PostPage extends Component {
  state = {}
  componentDidMount() {
    window.location.href = `https://smileinndental.com/blog/${this.props.data.post.slug}`
  }
  render() {
    const { post } = this.props.data
    return (
      <>
        <SEO title={post.title} description={post.excerpt} />
        <RedirectLoader />
      </>
    )
  }

  // componentDidMount = () => {
  //   this.setState(this.getPosts())
  // }

  // getPosts = () => {
  //   const pages = this.props.data.allSitePage.edges
  //   //Get the urls in an array
  //   const paths = pages.map(page => page.node.path)

  //   //Get the index of the current page
  //   var currentPage = paths.indexOf(`${this.props.location.pathname}/`)
  //   if (currentPage === -1) {
  //     currentPage = paths.indexOf(`${this.props.location.pathname}`)
  //   }

  //   //Look ahead and before to determine if the pages are also commercials
  //   const ahead = paths[currentPage + 1] || ""
  //   const before = paths[currentPage - 1] || ""

  //   var previousPath
  //   var nextPath

  //   if (before.startsWith("/blog/")) {
  //     previousPath = before
  //   } else {
  //     previousPath = null
  //   }

  //   if (ahead.startsWith("/blog/")) {
  //     nextPath = ahead
  //   } else {
  //     nextPath = null
  //   }

  //   //Create the object to return
  //   const navigation = {
  //     previous: previousPath,
  //     next: nextPath,
  //   }

  //   //Return object with before and after paths
  //   return navigation
  // }
  // render() {
  //   const { post } = this.props.data
  //   const { previous, next } = this.state
  //   return (
  //     <Layout noHeader>
  //       <SEO title={post.title} description={post.excerpt} />
  //       <Container>
  //         <ImageContainer>
  //           <PostImage src={post?.featured_media?.source_url} />
  //         </ImageContainer>
  //         <Title>{post.title}</Title>
  //         <Meta>
  //           <PostDate>{moment(post.date).format("MMM Do, YYYY")}</PostDate>
  //           <Author>by Shenilee Hazell</Author>
  //         </Meta>
  //         <InlineShareButtons
  //           config={{
  //             alignment: "center", // alignment of buttons (left, center, right)
  //             color: "social", // set the color of buttons (social, white)
  //             enabled: true, // show/hide buttons (true, false)
  //             font_size: 14, // font size for the buttons
  //             labels: "cta", // button labels (cta, counts, null)
  //             language: "en", // which language to use (see LANGUAGES)
  //             networks: [
  //               "facebook", // which networks to include (see SHARING NETWORKS)
  //               "twitter",
  //               "whatsapp",
  //               "messenger",
  //               "linkedin",
  //             ],
  //             padding: 12, // padding within buttons (INTEGER)
  //             radius: 4, // the corner radius on each button (INTEGER)
  //             show_total: false,
  //             size: 30, // the size of each button (INTEGER)

  //             // OPTIONAL PARAMETERS
  //             url: `https://www.smileinntt.com/blog/${post.title}`, // (defaults to current url)
  //             // image: post.featured_media.source_url, // (defaults to og:image or twitter:image)
  //             title: post.title, // (defaults to og:title or twitter:title)
  //           }}
  //         />

  //         <Content dangerouslySetInnerHTML={{ __html: post.content }} />
  //         <BottomNav>
  //           <Previous>
  //             {previous && (
  //               <>
  //                 <FaArrowLeft />{" "}
  //                 <Link
  //                   style={{
  //                     textDecoration: "none",
  //                     color: "black",
  //                     marginLeft: "5px",
  //                     fontFamily: "Articulat",
  //                     fontWeight: 500,
  //                   }}
  //                   to={previous}
  //                 >
  //                   Previous Post
  //                 </Link>
  //               </>
  //             )}
  //           </Previous>
  //           <Next>
  //             {next && (
  //               <>
  //                 <Link
  //                   style={{
  //                     textDecoration: "none",
  //                     color: "black",
  //                     marginRight: "5px",
  //                     fontFamily: "Articulat",
  //                     fontWeight: 500,
  //                   }}
  //                   to={next}
  //                 >
  //                   Next Post
  //                 </Link>{" "}
  //                 <FaArrowRight />
  //               </>
  //             )}
  //           </Next>
  //         </BottomNav>
  //         <div
  //           style={{
  //             width: "100%",
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <AppointmentButton title="Book a consultation now" />
  //         </div>
  //         <ShareTitle>Share this post:</ShareTitle>
  //         <InlineShareButtons
  //           config={{
  //             alignment: "center", // alignment of buttons (left, center, right)
  //             color: "social", // set the color of buttons (social, white)
  //             enabled: true, // show/hide buttons (true, false)
  //             font_size: 14, // font size for the buttons
  //             labels: "cta", // button labels (cta, counts, null)
  //             language: "en", // which language to use (see LANGUAGES)
  //             networks: [
  //               "facebook", // which networks to include (see SHARING NETWORKS)
  //               "twitter",
  //               "whatsapp",
  //               "messenger",
  //               "linkedin",
  //             ],
  //             padding: 12, // padding within buttons (INTEGER)
  //             radius: 4, // the corner radius on each button (INTEGER)
  //             show_total: false,
  //             size: 30, // the size of each button (INTEGER)

  //             // OPTIONAL PARAMETERS
  //             url: `https://www.smileinntt.com/blog/${post.title}`, // (defaults to current url)
  //             // image: post.featured_media.source_url, // (defaults to og:image or twitter:image)
  //             title: post.title, // (defaults to og:title or twitter:title)
  //           }}
  //         />
  //         <br />
  //       </Container>
  //     </Layout>
  //   )
  // }
}

export default PostPage

export const PostPageQuery = graphql`
  query PostQuery($id: String!) {
    post: wordpressPost(id: { eq: $id }) {
      title
      excerpt
      content
      slug
      featured_media {
        source_url
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`
