import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import MainButton from "../../components/main-button"
import ServiceItem from "../../components/service-item"
import { Colors } from "../../colors/Colors"
import { graphql, Link } from "gatsby"
import DentistryHeader from "../../components/dentistry-header"
import BreadCrumbs from "../../components/breadcrumbs"
import SEO from "../../components/seo"
import { AppointmentButton } from "../../components/appointment/appointment-button"
import RedirectLoader from "../../components/redirect-loader"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 25px;
`
const Description = styled.p`
  text-align: center;

  ::after {
    content: "";
    display: block;
    width: 150px;
    height: 5px;
    background-color: ${Colors.gold};
    margin: 50px auto;
  }
`
const Header = styled.h2`
  margin: 50px 0 50px 0;
  font-weight: 800;
  font-size: 24px;
`
const ServiceList = styled.div`
  width: 100%;
`

class GeneralPage extends Component {
  state = {}
  componentDidMount() {
    window.location.href =
      "https://smileinndental.com/services/general-dentistry"
  }
  render() {
    return (
      <>
        <SEO
          title="General Dentistry"
          description="Smile Inn Dental offers world-class General Dentistry in a welcoming and comfortable setting where patients are cared for like family. Make an appointment online today!"
        />
        <RedirectLoader />
      </>
    )
  }
  // render() {
  //   const data = this.props.data.allWordpressPost.edges
  //   return (
  //     <Layout>
  //       <SEO
  //         title="General Dentistry"
  //         description="Smile Inn Dental offers world-class General Dentistry in a welcoming and comfortable setting where patients are cared for like family. Make an appointment online today!"
  //       />
  //       <PageHeader noLogo image={require("../../images/general.jpg")} />
  //       <DentistryHeader
  //         title="General Dentistry"
  //         subtitle="General dentistry aims to ensure that your mouth is free from any oral disease or pathology and ensure a healthy mouth."
  //       />
  //       <Container>
  //         <BreadCrumbs page="General Dentistry" />

  //         <Header>General Services</Header>
  //         <ServiceList>
  //           {data.map((service, index) => (
  //             <ServiceItem
  //               key={index}
  //               title={service.node.title}
  //               short_description={service.node.acf.short_description}
  //             />
  //           ))}
  //         </ServiceList>
  //         <AppointmentButton title="Book an appointment Online" />
  //       </Container>
  //     </Layout>
  //   )
  // }
}

export default GeneralPage

export const generalQuery = graphql`
  query($category: String = "General Dentistry") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          content
          acf {
            short_description
          }
        }
      }
    }
  }
`
