import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { Colors } from "../colors/Colors"
import Loader from "react-loader-spinner"

export default function RedirectLoader() {
  return (
    <Container>
      <Logo src={require("../images/logo_alt.png")} />
      <Fade>
        <Copy>Redirecting you to our website...</Copy>
      </Fade>
      <Loader type="Oval" color={Colors.gold} height={40} width={40} />
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 20px;
`
const Logo = styled.img`
  max-width: 80px;
  object-fit: contain;
  margin: 0;
`
const Copy = styled.p`
  font-family: "Articulat";
  font-weight: 600;
  font-size: 1.2rem;
  color: #404040;
  line-height: 2.2rem;
  max-width: 25ch;
  text-align: center;
  margin: 20px 0;
`
