import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Loader from "react-loader-spinner"
import { Colors } from "../colors/Colors"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 20px;
`
const Logo = styled.img`
  max-width: 80px;
  object-fit: contain;
  margin: 0;
`
const Copy = styled.p`
  font-family: "Articulat";
  font-weight: 600;
  font-size: 1.2rem;
  color: #404040;
  line-height: 2.2rem;
  max-width: 25ch;
  text-align: center;
  margin: 20px 0;
`

class IndexPage extends React.Component {
  state = {}
  componentDidMount() {
    window.location.href = "https://smileinndental.com"
  }
  render() {
    return (
      <>
        <SEO title="Dr. Shenilee Hazell - Dentist in Trinidad & Tobago" />
        <Container>
          <Logo src={require("../images/logo_alt.png")} />
          <Fade>
            <Copy>Redirecting you to our website...</Copy>
          </Fade>
          <Loader type="Oval" color={Colors.gold} height={40} width={40} />
        </Container>
      </>
    )
  }
}
// class IndexPage extends React.Component {
//   state = {}
//   componentDidMount() {
//     window.location.href = "https://smileinndental.com";
//   }
//   render() {
//     const data = this.props.data.home.edges[0].node.acf
//     const services = this.props.data.services.edges
//     const teamData = this.props.data.team.acf.team
//     const slideCopy = [
//       "",
//       data.caption_1,
//       data.caption_2,
//       data.caption_3,
//       data.caption_4,
//       data.caption_5,
//     ]
//     return (
//       <Layout>
//         <SEO title="Dr. Shenilee Hazell - Dentist in Trinidad & Tobago" />
//         <IntroHeader slides={data.slideshow} copies={slideCopy} />
//         <ServiceContainer style={{ background: "white", paddingTop: 40 }}>
//           <ServiceTabs />
//         </ServiceContainer>
//         <Invisalign />
//         <FeaturedImage />
//         <IntroSection
//           intro={data.site_intro}
//           testimonials={this.props.data.testimonials.edges}
//         />
//         <OurServices services={services} />
//         <MeetTheTeam teamData={teamData} />
//         <NewPatient />
//       </Layout>
//     )
//   }
// }

export default IndexPage

export const dentistryQuery = graphql`
  query(
    $homepage: String = "Home"
    $category: String = "Google Review"
    $type: String = "Service"
  ) {
    home: allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          content
          acf {
            slideshow {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 2500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            site_intro
            caption_1
            caption_2
            caption_3
            caption_4
            caption_5
          }
        }
      }
    }
    testimonials: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
    services: allWordpressPage(
      filter: { acf: { type: { eq: $type } } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
    team: wordpressPage(title: { eq: "Home" }) {
      acf {
        team {
          image {
            source_url
          }
          name
          role
        }
      }
    }
  }
`
