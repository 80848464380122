import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import MainButton from "../../components/main-button"
import ServiceItem from "../../components/service-item"
import { graphql, Link } from "gatsby"
import DentistryHeader from "../../components/dentistry-header"
import BreadCrumbs from "../../components/breadcrumbs"
import SEO from "../../components/seo"
import { AppointmentButton } from "../../components/appointment/appointment-button"
import RedirectLoader from "../../components/redirect-loader"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
`
const Header = styled.h2`
  margin: 50px 0;
  font-weight: 800;
  font-size: 24px;
`
const ServiceList = styled.div`
  width: 100%;
`

class ChildrensPage extends Component {
  state = {}
  componentDidMount() {
    window.location.href =
      "https://smileinndental.com/services/childrens-dentistry"
  }
  render() {
    return (
      <>
        <SEO
          title="Children's Dentistry"
          description="Smile Inn Dental offers world-class Children's Dentistry in a welcoming and comfortable setting where children are cared for like family. Make an appointment online today!"
        />
        <RedirectLoader />
      </>
    )
  }
  // render() {
  //   const data = this.props.data.allWordpressPost.edges
  //   return (
  //     <Layout>
  //       <SEO
  //         title="Children's Dentistry"
  //         description="Smile Inn Dental offers world-class Children's Dentistry in a welcoming and comfortable setting where children are cared for like family. Make an appointment online today!"
  //       />

  //       <PageHeader noLogo image={require("../../images/childrens.jpg")} />
  //       <DentistryHeader
  //         title="Children's Dentistry"
  //         subtitle="Smile Inn provides a friendly and welcoming environment with special-sized dental equipment"
  //       />
  //       <Container>
  //         <BreadCrumbs page="Children's Dentistry" />

  //         <Header>Children's Services</Header>
  //         <ServiceList>
  //           {data.map((service, index) => (
  //             <ServiceItem
  //               key={index}
  //               title={service.node.title}
  //               short_description={service.node.acf.short_description}
  //             />
  //           ))}
  //         </ServiceList>
  //         <AppointmentButton />
  //       </Container>
  //     </Layout>
  //   )
  // }
}

export default ChildrensPage

export const childrenQuery = graphql`
  query($category: String = "Children's Dentistry") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          content
          acf {
            short_description
          }
        }
      }
    }
  }
`
