import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import IntroHeader from "../components/intro-header"
import { SectionTitle } from "../components/Section-Title"
import { Colors } from "../colors/Colors"
import header from "../images/main-header/4.jpg"
import PageHeader from "../components/page-header"
import RedirectLoader from "../components/redirect-loader"
import SEO from "../components/seo"

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 20px;
`
const Copy = styled.div`
  flex: 2;
  p {
    font-family: "Articulat";
    margin: 0 0 25px 0;
  }
  span {
    color: ${Colors.gold};
  }
`
const Logo = styled.img`
  flex: 1;
  max-height: 100px;
  width: auto;
  object-fit: contain;
  margin: 0;

  @media (max-width: 325px) {
    display: none;
  }
`

const ContactPage = props => {
  useEffect(() => {
    window.location.href = "https://smileinndental.com/contact"
  }, [])

  return (
    <Layout noHeader noNav>
      <SEO title="Contact Us" />
      <RedirectLoader />
    </Layout>
  )
  return (
    <Layout>
      <PageHeader image={header} />
      <SectionTitle style={{ padding: "30px 20px" }}>Contact Us</SectionTitle>
      <ContactContainer>
        <Copy>
          <p>
            Smile Inn Dental <br />5 Roberts Street, <br />
            Woodbrook, <br />
            Trinidad & Tobago
          </p>
          <p>
            Phone:{" "}
            <span>
              <a
                style={{ color: "inherit", textDecoration: "inherit" }}
                href="tel:8682259228"
              >
                (868) 225-9228
              </a>
            </span>
            <br />
            Email:{" "}
            <span>
              <a
                style={{ color: "inherit", textDecoration: "inherit" }}
                href="mailto:smileinntt@gmail.com"
              >
                smileinntt@gmail.com
              </a>
            </span>
          </p>
          <p>
            We are here to help you with any questions or concerns you may have
            about our practice. Please feel free to contact our Customer Support
            team at smileinntt@gmail.com.{" "}
          </p>
        </Copy>
        {/* <Logo src={require("../images/logo_alt.png")} /> */}
      </ContactContainer>
    </Layout>
  )
}

export default ContactPage
