import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import MainButton from "../../components/main-button"
import ServiceItem from "../../components/service-item"
import { graphql, Link } from "gatsby"
import DentistryHeader from "../../components/dentistry-header"
import BreadCrumbs from "../../components/breadcrumbs"
import SEO from "../../components/seo"
import { AppointmentButton } from "../../components/appointment/appointment-button"
import RedirectLoader from "../../components/redirect-loader"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
`
const Header = styled.h2`
  margin: 50px 0;
  font-weight: 800;
  font-size: 24px;
`
const ServiceList = styled.div`
  width: 100%;
`

class CosmeticPage extends Component {
  state = {}
  componentDidMount() {
    window.location.href =
      "https://smileinndental.com/services/cosmetic-dentistry"
  }
  render() {
    return (
      <>
        <SEO
          title="Cosmetic Dentistry"
          description="Smile Inn Dental offers world-class Cosmetic Dentistry in a welcoming and comfortable setting where patients are cared for like family. Make an appointment online today!"
        />
        <RedirectLoader />
      </>
    )
  }
  // render() {
  //   const data = this.props.data.allWordpressPost.edges
  //   return (
  //     <Layout>
  //       <SEO
  //         title="Cosmetic Dentistry"
  //         description="Smile Inn Dental offers world-class Cosmetic Dentistry in a welcoming and comfortable setting where patients are cared for like family. Make an appointment online today!"
  //       />
  //       <PageHeader noLogo image={require("../../images/cosmetic.jpg")} />
  //       <DentistryHeader
  //         title="Cosmetic Dentistry"
  //         subtitle="Don't settle for a smile that doesn't make you feel amazing! We have a wide variety of services that can completely transform your smile!"
  //       />
  //       <Container>
  //         <BreadCrumbs page="Cosmetic Dentistry" />

  //         <Header>Cosmetic Services</Header>
  //         <ServiceList>
  //           {data.map((service, index) => (
  //             <ServiceItem
  //               key={index}
  //               title={service.node.title}
  //               short_description={service.node.acf.short_description}
  //             />
  //           ))}
  //         </ServiceList>
  //         <AppointmentButton title="Book an appointment Online" />
  //       </Container>
  //     </Layout>
  //   )
  // }
}

export default CosmeticPage

export const cosmeticQuery = graphql`
  query($category: String = "Cosmetic Dentistry") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          acf {
            short_description
          }
        }
      }
    }
  }
`
