import { graphql } from "gatsby"
import React, { useEffect } from "react"
import styled from "styled-components"
import { Colors } from "../colors/Colors"
import BlogItem from "../components/blog-item"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RedirectLoader from "../components/redirect-loader"

const BlogPage = ({ data }) => {
  const blogPosts = data.blogPosts.edges

  useEffect(() => {
    window.location.href = "https://smileinndental.com/blog"
  }, [])

  return (
    <Layout noHeader noNav>
      <SEO title="Blog" />
      <RedirectLoader />
    </Layout>
  )

  return (
    <Layout noHeader>
      <SEO title="Blog" />
      <Container>
        <Title>The Smile Inn Dental Blog</Title>
        <Body>
          {/* <Balancer> */}
          Read our blog to learn more about dental health, dental care, and
          more.
          {/* </Balancer> */}
        </Body>
        <SectionTitle>Recent Blog Posts</SectionTitle>
        <BlogSection>
          {blogPosts.map((blog, index) => (
            <BlogItem
              key={index}
              index={index}
              post={blog.node}
              title={blog.node.title}
              excerpt={blog.node.excerpt}
              slug={blog.node.slug}
            />
          ))}
        </BlogSection>
      </Container>
    </Layout>
  )
}

export default BlogPage

const Container = styled.main`
  padding: 20vh 20px;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  > img {
    margin: 0 auto;
    margin-bottom: 18px;
    text-align: center;
  }

  @media (max-width: 750px) {
    padding: 6vh 20px;
  }
`
const Logo = styled.img`
  max-width: 80px;
  object-fit: contain;
  margin: 0;
`
const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: ${Colors.gold};
`
const Body = styled.p`
  font-size: 1rem;
  line-height: 1.9rem;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 35ch;
  margin: 0 auto;
  margin-bottom: 100px;
`
const SectionTitle = styled.h2`
  font-family: "Articulat";
  font-weight: 600;
  font-size: 1.2rem;
`
const BlogSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-bottom: 100px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`
export const blogPostsQuery = graphql`
  query($blogs: String = "Blog") {
    blogPosts: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $blogs } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          excerpt
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
